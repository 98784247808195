<template>
	<div
		class="full-height flex-column "
	>
		<Search
			:search="search"
			:option="search_option"

			@change="getData"
			@click="getData"
			@toItem="toItem"
		>
			<select
				v-model="search.member_level"
				slot="add"
				class="pa-5-10 mr-10"
				@change="getData"
			>
				<option value="">회원 등급</option>
				<option
					v-for="(code, index) in codes.M001.items"
					:key="'code_' + index"
					:value="code.code_index"
				>{{ code.code_name }}</option>
			</select>
		</Search>

		<div
			class="mt-10 full-height overflow-y-auto"
		>
			<div
				v-if="items.length > 0"
				class="full-height flex-column overflow-y-auto"
			>
				<div
					class="pa-10 full-height bg-white overflow-y-auto"
				>
					<table class="table table-even">
						<colgroup>
							<col width="80px" />
							<col width="auto" />
							<col width="180px" />
							<col width="180px" />
							<col width="180px" />
							<col width="180px" />
						</colgroup>
						<thead>
						<tr>
							<th><input type="checkbox" /></th>
							<th>제목</th>
							<th>내용</th>
							<th>회원등급</th>
							<th>등록일시</th>
							<th>관리</th>
						</tr>
						</thead>
						<tbody>
						<template
							v-for="(item, index) in items"
						>
							<tr
								:key="'bbs_' + item.uid"
							>
								<td><input type="checkbox" /></td>
								<td class="td-left">{{ item.b_title }}</td>
								<td>
									<button
										class="box pa-5-10"
										@click="is_view == item.uid ? is_view = null : is_view = item.uid"
									>내용보기
										<v-icon
											v-if="is_view == item.uid "
											small
											class=" color-icon cursor-pointer"
										>mdi mdi-menu-up</v-icon>
										<v-icon
											v-else
											small
											class=" color-icon cursor-pointer"
										>mdi mdi-menu-down</v-icon>
									</button>
								</td>
								<td>


									<select
										v-model="item.is_level"
										slot="add"
										class="pa-5-10 mr-10"
										@change="putBbs(item)"
									>
										<option
											v-for="(code, index) in codes.M001.items"
											:key="'code_' + index"
											:value="code.code_index"
										>{{ code.code_name }}</option>
									</select>
								</td>
								<td>{{ item.wDate }}</td>
								<td>
									<button
										class="btn-success pa-5-10 ml-10"

										@click="toDetail(item)"
									>수정</button>
									<button
										class="btn-danger pa-5-10 ml-10"
										@click="isDelete(item)"
									>삭제</button>
								</td>
							</tr>
							<tr
								v-if="is_view == item.uid"
								:key="'bbs_contents_' + item.uid"
							>
								<td></td>
								<td colspan="4" class="td-left ">
									<Viewer
										v-if="item.b_contents"
										:initialValue="item.b_contents"
									/>
								</td>
								<td>
									<div
										v-for="(file, f_index) in item.files"
										:key="'file_' + index + f_index"
										class="text-left box pa-5 mb-5 bg-white"
									>

										<button
											class="btn btn-primary-outline"
											@click="$bus.$emit('download', 'bbs', item.b_code, file.file_name)"
										> {{ file.original_name }}</button>

									</div>
								</td>
							</tr>
						</template>
						</tbody>
					</table>
				</div>

				<Pagination
					:program="program"
					:align="'center'"
					:options="search"

					@click="getSearch"

					class="mt-auto"
				></Pagination>
			</div>

			<Empty
				v-else
			></Empty>
		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"

			@close="doClear"
		>
			<template
				slot="modal-bottom"
			>
				<button
					class="flex-1 pa-10 bg-red color-eee"
					@click="doDelete"
				>삭제</button>
				<button
					class="flex-1 pa-10 bg-gray-light color-333"
					@click="doClear"
				>취소</button>
			</template>
		</Modal>
	</div>
</template>

<script>
import Search from "@/view/Layout/Search";
import Pagination from "@/components/Pagination";

import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from "@toast-ui/vue-editor";
import Modal from "@/components/Modal";
import Empty from "@/view/Layout/Empty";

export default {
	name: 'CustomerCenterNoticeList'
	, components: {Empty, Modal, Pagination, Search,Viewer}
	, props: ['Axios', 'TOKEN', 'codes', 'rules', 'user']
	, data: function(){
		return {
			program: {
				name: '공지사항'
				,top: true
				,title: true
				,bottom: false
			}
			,search: this.$storage.init({
				ATOKEN: this.TOKEN
				, b_code: 'b_notice'
				, list_cnt: 10
				, search_type: ''
				, search_value: ''
				, member_level: ''
			})
			,search_option: {
				is_excel: false
				,is_item: true
				,is_cnt: true
				,sDate: false
				,eDate: false
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '제목', column: 'b_title'}
				]
			}
			,items: [

			]
			,is_view: null
			,is_modal: false
			,modal_option: {
				top: true
				,title: '게시글 삭제'
				,content: '해당 게시글을 삭제하시겠습니까?'
				,bottom: true
				,width: '380px'
				, slot_bottom: true
			}
		}
	}
	, methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getBbsList'
					,data: this.search
				})
				if(result.success){
					// console.log(result.data.result)
					this.items = result.data.result
					this.$set(this.search, 'total_count', result.data.tCnt)
					this.$set(this.search_option, 'tCnt', result.data.tCnt)
					this.$set(this.search_option, 'cnt', result.data.cnt)
					this.$storage.setQuery(this.search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toItem: function(){
			this.$router.push({ name: 'BbsItem', params: { b_code: this.search.b_code}})
		}
		, toDetail: function(item){
			if(this.user.role == this.codes.type_code_admin || this.user.role == this.codes.type_code_agency){
				this.$router.push({ name: 'BbsDetail', params: { b_code: this.search.b_code, bbs_uid: item.uid }})
			}else{
				this.$router.push({ name: 'BbsView', params: { b_code: this.search.b_code, bbs_uid: item.uid }})
			}

		}
		, doClear: function(){
			this.is_modal = false
			this.item_delete = null
		}
		, doDelete: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postBbsDelete'
					,data: {
						ATOKEN: this.TOKEN
						,bbs_uid: this.item_delete.uid
						,b_code: this.$route.params.b_code
					}
				})

				if(result.success){
					this.doClear()
					await this.getData()
					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, isDelete: function(item){
			this.is_modal = true
			this.item_delete = item
		}
		, getSearch: function(page){
			if(page){
				this.search.page = page
			}

			this.getData()
		}
		, putBbs: async function(item){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/putBbs'
					,data: {
						uid: item.uid
						, is_level: item.is_level
					}
				})

				if(result.success){

					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>